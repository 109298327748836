import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.NEWMENU.HOME',
        icon: 'bx-home-circle',
        link: '/',
        badge: {
            variant: 'info',
            text: 'MENUITEMS.DASHBOARDS.BADGE',
        }

    },
    {
        id: 3,
        label: 'MENUITEMS.NEWMENU.BUS',
        icon: 'bx-home-circle',
        link: '/',
        badge: {
            variant: 'info',
            text: 'MENUITEMS.DASHBOARDS.BADGE',
        }

    },
    {
        id: 4,
        label: 'MENUITEMS.NEWMENU.STUDENTS',
        icon: 'bx-home-circle',
        link: '/',
        badge: {
            variant: 'info',
            text: 'MENUITEMS.DASHBOARDS.BADGE',
        }

    }




    
        
    
];

