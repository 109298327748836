import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import {environment } from  '../../../environments/environment'
import {User} from '../../core/models/auth.models';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  public token:String;
  constructor(private http: HttpClient) { 
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
 
    this.user = this.userSubject.asObservable();
    this.token = localStorage.getItem('access_token');
  }
  public get userValue(): User {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
    return this.userSubject.value;
}


public get tokenValue(): String {
  this.token = localStorage.getItem('access_token');
  return this.token;
}

  login(payload) {
    return this.http.post<any>(environment.apiUrl + 'api/login',payload)
        .pipe(map(data => {
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('access_token', data.token);
            this.userSubject.next(data.user);
            return data;
        }));
}

  

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
  }

  public get loggedIn(): boolean {
    return (localStorage.getItem('access_token') !== null);
  }
}
