import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import {AuthService} from '../../../app/account/auth/auth.service'
;
import { environment } from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private _authService:AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
        if (this._authService.user && this._authService.tokenValue) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this._authService.tokenValue}`
                }
            });
        }
        
        return next.handle(request);
    }
}
